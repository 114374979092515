import axios from "axios";
import websiteURL from "@/websiteURL";

//Permet la recuperation des etiquettes
export async function fetchEtiquette(perPage, curentPage) {
  const apiUrl = websiteURL.WEBSITE + "wp-json/wc/v3/products/tags";
  const consumerKey = websiteURL.CONSUMERKEY;
  const consumerSecret = websiteURL.CONSUMERSECRET;
  try {
    const response = await axios.get(apiUrl, {
      auth: {
        username: consumerKey,
        password: consumerSecret,
      },
      params: {
        per_page: perPage,
        page: curentPage,
      },
    });
    const etiquettes = response.data;
    const totalEtiquettes = response.headers["x-wp-total"];
    const etiquetteInfo = { etiquettes, totalEtiquettes };

    return etiquetteInfo;
  } catch (error) {
    // Handle errors, e.g., show an error message
    console.error("Error fetching etiquettes:", error);
  }
}
