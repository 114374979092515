<template>
  <div class="app-ecommerce">
    <b-overlay
      :show="loading"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
      <div class="row">
        <div class="col-12 col-lg-5">
          <div class="card mb-4">
            <div class="card-header">
              <h3 class="card-tile mb-0">Ajout étiquette</h3>
            </div>
            <div class="card-body">
              <div class="mb-3">
                <H5 class="form-label">Nom </H5>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Nom étiquette"
                  v-model="nomEtiquette"
                />
                <label
                  >Le nom est la façon dont il apparaît sur votre site.</label
                >
              </div>
              <div class="mb-3">
                <H5 class="form-label">Slug </H5>
                <input
                  class="form-control"
                  placeholder="SLUG"
                  v-model="slugEtiquette"
                />

                <label
                  >Le « slug » est la version du nom normalisée pour les
                  URL.</label
                >
              </div>

              <div class="mb-3">
                <H5 class="form-label">Description </H5>
                <quill-editor v-model="descriptionEtiquette" />
              </div>

              <div class="d-flex flex-row-reverse">
                <button
                  :disabled="nomEtiquette.length === 0"
                  type="submit"
                  class="btn btn-primary"
                  @click="etiquetteAddShowMsgBox()"
                >
                  Enregistrer
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-7">
          <div class="card mb-4">
            <div class="card-header">
              <h3 class="card-title mb-0">Liste étiquettes</h3>
            </div>
            <div>
              <b-table
                :items="listEtiquettes.etiquettes"
                :fields="fields"
                :busy="isBusy"
                striped
                hover
              >
                <template #table-busy>
                  <div class="text-center text-primary my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong style="margin-left: 5px">Loading...</strong>
                  </div>
                </template>
                <template #cell(actions)="data">
                  <div class="text-nowrap">
                    <feather-icon
                      :id="`invoice-row-${data.item.id}-edit-icon`"
                      icon="EditIcon"
                      @click="RedirectToEditEtiquette(data.item.id)"
                    />
                    <b-tooltip
                      title="Modification"
                      :target="`invoice-row-${data.item.id}-edit-icon`"
                    />
                    <feather-icon
                      :id="`invoice-row-${data.item.id}-quick-edit-icon`"
                      icon="EditIcon"
                      v-b-modal.modal-1
                      class="mx-1"
                      @click="getEtiquetteInfo(data.item)"
                    />
                    <b-tooltip
                      title="Modification rapide"
                      :target="`invoice-row-${data.item.id}-quick-edit-icon`"
                    />

                    <feather-icon
                      :id="`invoice-row-${data.item.id}-trash-icon`"
                      icon="TrashIcon"
                      @click="deleteActionEtiquette(data.item.id)"
                    />
                    <b-tooltip
                      title="Supprimer"
                      :target="`invoice-row-${data.item.id}-trash-icon`"
                    />
                  </div>
                </template>
              </b-table>
              <b-pagination
                class="mt-3"
                align="center"
                v-model="currentPage"
                :total-rows="listEtiquettes.totalEtiquettes"
                :per-page="perPage"
                @change="actualisationEtiquette"
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
      <div>
        <QuickEdit
          v-if="showModal"
          :showModal="showModal"
          :name="etiquetteInfo.name"
          :slug="etiquetteInfo.slug"
          @ConfirmEdit="quickUpdateEtiquette"
        />
      </div>
    </b-overlay>
  </div>
</template>
<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import axios from "axios";
import websiteURL from "@/websiteURL";
import {
  BOverlay,
  BTable,
  BPagination,
  BSpinner,
  BTooltip,
  BRow,
  BCol,
  BFormInput,
} from "bootstrap-vue";
import { fetchEtiquette } from "../../views/Etiquettes/EtiquetteFunction.js";
import QuickEdit from "../../views/QuickEditModal/QuickEdit.vue";
export default {
  components: {
    quillEditor,
    BOverlay,
    BPagination,
    BTable,
    BSpinner,
    BTooltip,
    BRow,
    BCol,
    BFormInput,
    QuickEdit,
  },
  data() {
    return {
      nomEtiquette: "",
      slugEtiquette: "",
      descriptionEtiquette: "",
      boxMsgAddEtiquette: "",
      loading: false,
      listEtiquettes: [],
      fields: [
        {
          label: "Name",
          key: "name",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          label: "Slug",
          key: "slug",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          label: "Description",
          key: "description",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          label: "Actions",
          key: "actions",
          thClass: "text-center",
          tdClass: "text-center",
        },
      ],
      perPage: 5,
      currentPage: 1,
      etiquetteInfo: [],
      boxMsgDeleteEtiquette: "",
      isBusy: false,
      showModal: false,
    };
  },
  methods: {
    async RedirectToEditEtiquette(item) {
      this.$router.push({
        name: "Edit Etiquette",
        params: { id: item },
      });
    },
    clearData() {
      this.nomEtiquette = "";
      this.descriptionEtiquette = "";
      this.slugEtiquette = "";
    },
    //Permet l'ajout d'une nouvelle etiquette
    async addEtiquette() {
      const apiUrl = websiteURL.WEBSITE + "wp-json/wc/v3/products/tags";
      const consumerKey = websiteURL.CONSUMERKEY;
      const consumerSecret = websiteURL.CONSUMERSECRET;
      try {
        const data = {
          name: this.nomEtiquette,
          description: this.descriptionEtiquette,
          slug: this.slugEtiquette,
        };

        const response = await axios.post(apiUrl, data, {
          auth: {
            username: consumerKey,
            password: consumerSecret,
          },
        });
        this.etiquetteAddToastMsg("info");
        this.clearData();
      } catch (error) {
        alert(
          "Erreur lors de l'ajout de l'étiquette, veuillez réessayer plus tard :",
          error
        );
      }
    },
    //Message de confirmation d'ajout
    etiquetteAddShowMsgBox() {
      this.boxMsgAddEtiquette = "";
      this.$bvModal
        .msgBoxConfirm("Êtes-vous sûr de vouloir ajouter l'étiquette ?", {
          title: "Confirmation",
          size: "sm",
          okVariant: "primary",
          okTitle: this.loading ? "Loading..." : "Confirmer",
          cancelTitle: "Annuler",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          this.boxMsgAddEtiquette = value;
          if (value) {
            if (value) {
              this.loading = true;
              this.addEtiquette().then(async () => {
                this.loading = false;
                await this.actualisationEtiquette();
              });
            }
          }
        });
    },
    etiquetteAddToastMsg(variant = null) {
      this.$bvToast.toast("L'étiquette a bien été ajouter", {
        title: `Etiquette ${variant || "default"}`,
        variant,
        solid: true,
        href: this.permalink,
      });
    },
    async getEtiquetteInfo(item) {
      this.etiquetteInfo = item;
      this.showModal = !this.showModal;
    },
    //Permet la modification rapide d'une etiquette
    async quickUpdateEtiquette(info) {
      this.loading = true;
      const apiUrl =
        websiteURL.WEBSITE +
        `/wp-json/wc/v3/products/tags/${this.etiquetteInfo.id}`;
      const consumerKey = websiteURL.CONSUMERKEY;
      const consumerSecret = websiteURL.CONSUMERSECRET;
      const data = {
        name: info.name,
        slug: info.slug,
      };
      try {
        const response = await axios.put(apiUrl, data, {
          auth: {
            username: consumerKey,
            password: consumerSecret,
          },
        });
        this.loading = false;
        await this.actualisationEtiquette();
        this.currentPage = 1;
      } catch (error) {
        // Handle errors, e.g., show an error message
        alert(
          "Erreur lors de la mise à jour des informations, veuillez réessayer plus tard :",
          error
        );
      }
    },
    deleteActionEtiquette(idEtiquette) {
      this.etiquetteId = idEtiquette;
      this.showMsgBoxDelete();
    },
    //Permet la suppression d'une etiquette
    async deleteEtiquette() {
      const apiUrl =
        websiteURL.WEBSITE + `wp-json/wc/v3/products/tags/${this.etiquetteId}`;
      const consumerKey = websiteURL.CONSUMERKEY;
      const consumerSecret = websiteURL.CONSUMERSECRET;
      try {
        const response = await axios.delete(apiUrl, {
          params: {
            force: true,
          },
          auth: {
            username: consumerKey,
            password: consumerSecret,
          },
        });
        await this.actualisationEtiquette();
        this.etiquetteDeleteToastMsg("info");
        this.currentPage = 1;
      } catch (error) {
        alert(
          "Erreur lors de la suppression de l'étiquette, veuillez réessayer plus tard",
          error
        );
      }
    },
    //Message de confirmation de suppression
    showMsgBoxDelete() {
      this.boxMsgDeleteEtiquette = "";
      this.$bvModal
        .msgBoxConfirm("Êtes-vous sûr de vouloir supprimer l'étiquette ?", {
          title: "Confirmation",
          size: "sm",
          okVariant: "primary",
          okTitle: this.loading ? "Loading..." : "Confirmer",
          cancelTitle: "Annuler",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          this.boxMsgDeleteEtiquette = value;
          if (value) {
            if (value) {
              this.loading = true;
              this.deleteEtiquette().then(async () => {
                this.loading = false;
                await this.actualisationEtiquette();
              });
            }
          }
        });
    },
    etiquetteDeleteToastMsg(variant = null) {
      this.$bvToast.toast("L'étiquette a bien été supprimer", {
        title: `Etiquette ${variant || "default"}`,
        variant,
        solid: true,
        href: this.permalink,
      });
    },
    //Permet l'actualisation du tableau
    async actualisationEtiquette(event) {
      this.isBusy = true;
      if (event === null) {
        this.listEtiquettes = await fetchEtiquette(
          this.perPage,
          this.currentPage
        );
      } else {
        this.listEtiquettes = await fetchEtiquette(this.perPage, event);
      }
      this.isBusy = false;
    },
  },
  async created() {
    await this.actualisationEtiquette();
  },
};
</script>
<style lang="scss">
// Style
.ql-snow {
  &.ql-container {
    .ql-editor {
      min-height: 150px;
    }
  }
}
</style>
